import Vuex from 'vuex';
import ls from 'local-storage';

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    // The command ls returns 'token''s variable content
    isLogged: !! ls('token')
  },
  mutations: {
    loginUser(state) {
      state.isLogged = true
    },
    logoutUser(state) {
      state.isLogged = false
    }
  }
});