import { createWebHistory, createRouter } from 'vue-router';

import HelloWorld from '../components/HelloWorld.vue'
import Login from '../components/app/auth/Login'
import Logout from '../components/app/auth/Logout'
import Users from '../components/users/Index'

const routes = [
    {
        name: 'Hello World',
        path: '/hw',
        component: HelloWorld
    },
    {
        name: 'Login',
        path: '/login',
        component: Login
    },
    {
        name: 'Logout',
        path: '/logout',
        component: Logout
    },
    {
        name: 'Users',
        path: '/users',
        component: Users
    },
];

export default createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes
});