<template>
  <b-container fluid>
    <router-view></router-view>
  </b-container>
</template>

<script>
import store from '@/store';

export default {
  name: 'App',
  created() {
    document.title = "Alianza America"
  },
  beforeCreate() {

    if ( ! store.state.isLogged) {

      this.$router
        .push('/login')
        .catch(() => {
          console.log('Not logged, push login !')
        });
    }
    else {
      this.$router
        .push('/users')
        .catch(() => {
          console.log('Logged !')
        });
    }
  },
  computed: {
    isLogged() {
      return store.state.isLogged;
    }
  }
}
</script>