<template>
    <!-- Show existing data -->
    <b-container fluid>
        <b-container class="fixed-top" fluid>

            <b-row class="py-2" v-show="! modalShow">

                <!-- Alianza America logo -->
                <b-col md="6">
                    <AALogo/>
                </b-col>
                <!-- End of Alianza America logo -->

                <!-- Add button -->
                <b-col md="6" class="d-flex justify-content-end">
                    <font-awesome-icon
                        icon="circle-plus"
                        class="text-success fa-2xl float"
                        @click="create"></font-awesome-icon>
                </b-col>
                <!-- End of add button -->

            </b-row>

            <b-row class="py-2" v-show="modalShow">

                <!-- Alianza America logo -->
                <b-col md="2">
                    <AALogo/>
                </b-col>
                <!-- End of Alianza America logo -->

                <!-- Main form -->
                <b-col md="8">
                    <b-card
                        :header="modalTitle"
                        :header-bg-variant="formBgVariant"
                        header-text-variant="white bold"
                        header-class="text-bold"
                        v-show="modalShow"
                        class="p-0 m-0 shadow-lg"
                        >
                        <b-card-body class="p-0 m-0">
                            <Form
                                @get-all-function="getAllData"
                                @hide-form="hideForm"
                                :state="state"
                                :userObj="selected" />
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col md="2" class="d-flex justify-content-end">
                    <font-awesome-icon
                        icon="circle-plus"
                        class="text-secondary fa-2xl float"
                        disabled></font-awesome-icon>
                </b-col>
                <!-- End of main form -->

            </b-row>

        </b-container>

        <b-container :style="listTopMargin" fluid>

            <!-- Main List -->
            <b-row class="py-2">
                <b-col md="10" offset-md="1" xl="8" offset-xl="2">
                    <b-card header="Usuários Cadastrados" header-bg-variant="warning">

                        <b-card-body>
                            <!-- Users list -->
                            <b-row>
                                <b-col md="12">
                                    <!-- Data table -->
                                    <b-table
                                        id="tabela"
                                        :items="users"
                                        :fields="fields"
                                        small striped
                                        >
                                        <template #cell(_id)="data">
                                            <font-awesome-icon icon="id-card" v-b-tooltip.hover="{variant: 'danger'}" :title="data.item._id">
                                            </font-awesome-icon>
                                        </template>
                                        <template #cell(actions)="user">
                                            <b-row>
                                                <b-col md="12" class="d-flex justify-content-end">
                                                    <b-button @click="change(user.item)" variant="default" size="sm" class="shadow">
                                                        <font-awesome-icon icon="pen-to-square"></font-awesome-icon>
                                                    </b-button>
                                                    <b-button @click="remove(user.item)" variant="default" size="sm" class="shadow">
                                                        <font-awesome-icon icon="trash"></font-awesome-icon>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </template>
                                    </b-table>
                                    <!-- End of Data table -->
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <!-- End of Main List -->
        </b-container>

        <!-- Logout buttom -->
        <b-container class="fixed-bottom" fluid>
            <b-row class="py-2">
                <b-col md="12" class="d-flex justify-content-end">
                    <ButtomLogout />
                </b-col>
            </b-row>
        </b-container>
        <!-- End of Logout buttom -->

    </b-container>
</template>

<script>
import Form from './Form.vue';
import AALogo from '../app/frontpage/AALogo.vue'
import ButtomLogout from '../app/frontpage/ButtomLogout.vue'

export default {
    name: "Index",
    components: {
        Form,
        AALogo,
        ButtomLogout
    },
    data() {
        return {
            users: [],
            model: 'users',
            moduleName: 'Usuário',
            emptyForm: {
                'name': "",
                'login': "",
                'password': ""
            },
            state: undefined,
            selected: undefined,
            modalShow: false,
            compact: false,
            filter: '',
            currentPage: 1,
            totalRows: 0,
            fields: [
                {
                    key: '_id',
                    label: '#',
                    thAttr: {
                        width: '3%',
                        bgcolor: 'blue',
                        align: 'right'
                            },
                    class: 'text-left',
                    enabled: false
                },
                {
                    key: 'name',
                    label: 'Nome',
                    thAttr: { width: '30%', bgcolor: 'white' },
                    class: 'text-left'
                },
                {
                    key: 'login',
                    label: 'Login',
                    thAttr: { width: '30%', bgcolor: 'white' },
                    class: 'text-left',
                },
                {
                    key: 'actions',
                    label: 'Acões',
                    thAttr: { width: '10%', bgcolor: 'white' },
                    stickyColumn: true,
                    class: 'text-left'

                },
            ]
        }
    },
    mounted() {
        this.getAllData()
    },
    methods: {
        async getAllData() {
            let r = await this.$http.get(this.model);

            this.users = r;
            this.totalRows = r.length;
        },
        getInfo(user) {
            this.selected = Object.assign({}, user);
            this.state = this.$support.st.SELECTING;
            this.modalShow = true;
        },
        create() {
            this.selected = Object.assign({}, this.emptyForm);
            this.state = this.$support.st.CREATING;
            this.modalShow = true;
        },
        change(user) {
            this.selected = Object.assign({}, user);
            this.state = this.$support.st.UPDATING;
            this.modalShow = true;
        },
        remove(user) {
            this.selected = Object.assign({}, user);
            this.state = this.$support.st.DELETING;
            this.modalShow = true;
        },
        hideForm() {
            this.selected = Object.assign({}, this.emptyForm);
            this.state = undefined;
            this.modalShow = false;
        },
    },
    computed: {
        modalTitle() {
            let st = this.$support.st || {};
            let s;
            switch (this.state) {
                case st.SELECTING:
                    s = 'Detalhes do';
                    break;
                case st.UPDATING:
                    s = 'Editar dados do';
                    break;
                case st.DELETING:
                    s = 'Apagar dados do';
                    break;
                case st.CREATING:
                default:
                    s = 'Cadastrar um novo';
                    break;
            }
            s = s + ' ' + this.moduleName;
            return s;
        },
        formBgVariant() {
            let st = this.$support.st || {};
            let s;
            switch (this.state) {
                case st.SELECTING:
                    s = 'default';
                    break;
                case st.CREATING:
                    s = 'success';
                    break;
                case st.UPDATING:
                    s = 'primary';
                    break;
                case st.DELETING:
                    s = 'danger';
                    break;
            }
            return s;
        },
        listTopMargin() {
            let st = this.$support.st || {};
            return 'padding-top: '
                + (this.modalShow ?
                    ( this.state === st.UPDATING ? "175px" : "150px" ) : "5px") + ';';
        }
    },
}
</script>