<template>
  <div>
    Logout...
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: 'logout',
  mounted() {
    // Remove JWT Token
    localStorage.removeItem('token');

    // Commit a mutation
    store.commit('logoutUser');

    // Route to login option
    this.$router.push('/login');
  }
}
</script>
