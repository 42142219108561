<template>
  <b-container id="main-box" fluid>

    <b-row>
      <b-col md="4" offset-md="4">

        <form v-on:submit.prevent="login">

          <b-card class="border-alianza" fluid>

            <b-card-header class="p-0 m-0 bg-white">
                <b-img :src="require('../../../assets/img/logo_white_mini.png')" width="150%" fluid></b-img>
            </b-card-header>

            <b-card-body>

              <b-row>
                <b-col md="12">
                  <b-container fluid>

                    <b-input-group class="py-3 mb-2">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="user"></font-awesome-icon>
                      </b-input-group-prepend>
                      <b-form-input
                        id="input-1"
                        v-model="form.login"
                        type="text"
                        placeholder="Usuário"></b-form-input>
                    </b-input-group>

                  </b-container>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-container fluid>

                    <b-input-group class="py-3 mb-2">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="key"></font-awesome-icon>
                      </b-input-group-prepend>
                      <b-form-input
                        id="input-2"
                        v-model="form.password"
                        type="password"
                        placeholder="Senha de Autenticação"></b-form-input>
                    </b-input-group>

                  </b-container>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12" v-show="infoError">
                  <b-container fluid>
                    <strong><font color="red">{{ message }}</font></strong>
                  </b-container>
                </b-col>
              </b-row>

            </b-card-body>

            <b-card-footer>
              <b-row>
                <b-col md="12" class="text-center py-2">
                  <b-button type="submit" class="alianza">Login</b-button>
                </b-col>
              </b-row>
            </b-card-footer>

          </b-card>

        </form>

      </b-col>
    </b-row>

  </b-container>
</template>

<script>
  import store from '@/store';
  import ls from 'local-storage';

  export default {
    name: 'login',
    data() {
      return {
        infoError: false,
        message: '',
        form: {
          login: '',
          encrypted: '',
          password: ''
        }
      };
    },
    beforeCreate() {
      if (store.state.isLogged) {
        this.$router.push('/');
      }
    },
    methods: {
      async login() {
        /* Hide message, if visible */
        if (this.infoError) {
          this.infoError = false;
        }

        /* Authenticate using backend api */
        let response = await this.$http.post('api/login', this.form);

        if (response.data.success && response.data.token) {
          this.infoError = true;
          this.message = 'Autenticado';

          /**
           *  Armazena o token em local-storage.
           *  O ls atua como "setter" quando o segundo valor é informado.
           */
          ls('token', response.data.token);

          /* Chama a mutation em store (através do commit ?) */
          store.commit('loginUser');

          /* Desvia para raiz */
          this.$router.push('/users');
        }
        else if ( ! response.data.success) {
          /* Em caso de erro, mostra a mensagem retornada */
          this.infoError = true;
          this.message = response.data.message;
        }
      }
    }
  };
</script>

<style>

    #main-box {
      margin-top: 10%;
    }

    .border-alianza {
      border-color: rgb(210, 54, 122);
    }
    .alianza {
      background-color: rgb(210, 54, 122);
    }

</style>