<template>
  <b-container fluid>
    <b-row>

      <b-col md="4" class="p-1 m-0">
          <b-form-group id="input-group-name">
            <b-form-input
                    id="input-name"
                    ref="username"
                    v-model="form.name"
                    type="text"
                    placeholder="Nome"
                    :readonly="! showEditingControllers()"
                    autocomplete="off"
                    required
                    ></b-form-input>
          </b-form-group>
      </b-col>

      <b-col md="3" class="p-1 m-0">
          <b-form-group id="input-group-login">
            <b-form-input
                    id="input-login"
                    v-model="form.login"
                    type="text"
                    placeholder="Login"
                    :readonly="! showEditingControllers()"
                    autocomplete="off"
                    required
                    ></b-form-input>
          </b-form-group>
      </b-col>

      <b-col md="3" class="p-1 m-0">
          <b-form-group
              id="input-group-password"
              :description="this.localState === $support.st.UPDATING ? '* Só digite caso deseje alterar.' : ''"
            >
            <b-form-input
                id="input-password"
                v-model="newPassword"
                type="password"
                placeholder="Senha"
                :readonly="!showEditingControllers()"
                autocomplete="off"
              ></b-form-input>
          </b-form-group>
      </b-col>

      <b-col md="2" class="p-0 m-0">
        <b-container class="p-1 m-0 d-flex justify-content-end">

          <!-- Toolbar -->
          <b-button-group>
            <b-button
                    @click="saveFormData"
                    variant="danger"
                    :disabled="! hasChanged"
                    v-if='localState === $support.st.CREATING'
                    v-b-tooltip.hover title="Gravar os dados deste novo usuário.">
              <font-awesome-icon icon="floppy-disk"></font-awesome-icon>
            </b-button>
            <b-button
                    @click="saveFormData"
                    variant="danger"
                    :disabled="! hasChanged"
                    v-else-if='localState === $support.st.UPDATING'
                    v-b-tooltip.hover title="Gravar as modificações no registro do usuário.">
              <font-awesome-icon icon="floppy-disk"></font-awesome-icon>
            </b-button>
            <b-button
                    @click="deleteFormData"
                    variant="danger"
                    v-else-if='localState === $support.st.DELETING'
                    v-b-tooltip.hover title="Confirmar remoção do registro deste usuário.">
              <font-awesome-icon icon="trash"></font-awesome-icon>
            </b-button>
            <b-button
                    @click="dismissFormData"
                    variant="primary"
                    v-b-tooltip.hover title="Abandonar a operação."
                    >
              <font-awesome-icon icon="rotate-left"></font-awesome-icon>
            </b-button>
          </b-button-group>
          <!-- End of Toolbar -->

        </b-container>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'Index',
    data() {
      return {
        title: "Cadastro de Usuários",
        model: 'users',
        form: this.userObj || {},
        saveForm: Object.assign({}, this.userObj),
        newPassword: "",
        localState: this.state
      }
    },
    props: {
      userObj: {
        type: Object,
        required: false
      },
      state: {
        type: Number,
        required: false
      }
    },
    mounted() {
      this.$refs.username.focus();
    },
    methods: {
      async saveFormData() {
        // Valida o formulário
        let v = this.validate();

        // Só posta se estiver tudo ok
        if (v) {
          let st = this.$support.st;
          let r;

          // Faz o envio para o backend
          if (this.localState === st.UPDATING) {
            /* If an new password was set, send it as a new password */
            if (this.newPassword && ! this.newPassword.empty) {
              this.form.newPassword = this.newPassword;
            }

            r = await this.$http.put(this.model, this.form);
          }
          else if (this.localState === st.CREATING) {
            this.form.password = this.newPassword;

            r = await this.$http.post(this.model, this.form);
          }

          if (r && r.status && r.status === 200) {
            this.$toast(r.data.message, {
              title: this.title,
              autoClose: this.$settings.config.toastSuccessTimeout,
              appendToast: true,
              type: 'success'
            });

            // Refresh users list
            this.$emit('get-all-function');

            // Hide Form
            this.$emit('hideForm');
          }
          else {
            this.$toast(r.data, {
              title: this.title,
              autoClose: this.$settings.config.toastErrorTimeout,
              appendToast: true,
              type: 'danger'
            });
          }
        }

        // Reset password form's field
        this.newPassword = "";
      },
      async deleteFormData() {
        // Faz o delete para o backend
        let id = this.form._id;
        let r = await this.$http.delete(this.model + '/' + id, this.form);

        if (r && r.status && r.status === 200) {

          if (r.data.status === 'error') {
            this.$toast(r.data, {
              title: this.title,
              autoClose: this.$settings.config.toastErrorTimeout,
              appendToast: true,
              type: 'danger'
            });
          }
          else {
            this.$toast(r.data.message, {
              title: this.title,
              autoClose: this.$settings.config.toastErrorTimeout,
              appendToast: true,
              type: 'success'
            });
          }
        }
        else {
          this.$toast(r.data, {
            title: this.title,
            autoClose: this.$settings.config.toastErrorTimeout,
            appendToast: true,
            type: 'danger'
          });
        }

        // Refresh users list and hide form
        this.dismissFormData()
      },
      dismissFormData() {
        // Refresh users list and hide form
        this.$emit('get-all-function');
        this.$emit('hide-form');
      },
      validate() {

        if ( ! this.form.name || this.form.name.empty) {
          this.$toast("O nome do usuário é uma informação obrigatória.", {
            title: this.title,
            autoClose: this.$settings.config.toastErrorTimeout,
            appendToast: true,
            type: 'danger'
          });

          document.getElementById('input-name').focus();

          return false;
        }

        if ( ! this.form.login || this.form.login.empty) {
          this.$toast("O login do usuário é uma informação obrigatória.", {
            title: this.title,
            autoClose: this.$settings.config.toastErrorTimeout,
            appendToast: true,
            type: 'danger'
          });

          document.getElementById('input-login').focus();

          return false;
        }

        let st = this.$support.st;

        if (( ! this.newPassword || this.newPassword.empty) && this.localState === st.CREATING) {
          this.$toast("É obrigatório informar uma senha para cadastrar um novo usuário.", {
            title: this.title,
            autoClose: this.$settings.config.toastErrorTimeout,
            appendToast: true,
            type: 'danger'
          });

          document.getElementById('input-password').focus();

          return false;
        }

        return true;
      },
      showEditingControllers() {
        let st = this.$support.st;
        return (this.localState === st.CREATING || this.localState === st.UPDATING);
      },
    },
    computed: {
      hasChanged: function() {
        return Object.keys(this.form).some(field => this.form[field] !== this.saveForm[field]);
      }
    },
    watch: {
      userObj: function () {
        this.form = this.userObj;
      },
      state: function () {
        this.localState = this.state;
      }
    }
  }
</script>