<template>

    <!-- Buttom Logout -->
    <font-awesome-icon
        icon="right-from-bracket"
        class="text-danger fa-2xl float"
        @click="doLogout"></font-awesome-icon>
    <!-- End of Buttom Logout -->

</template>

<script>
export default {
    name: "ButtomLogout",
    methods: {
        doLogout() {
            this.$router.push('/logout');
        }
    }
}
</script>