<template>

    <!-- ADRs Logo -->
    <b-navbar-brand href="#">
        <b-container class="p-0 m-0">
            <b-img :src="require('@/assets/img/logo_white_mini.png')" width="160%"></b-img>
        </b-container>
    </b-navbar-brand>
    <!-- End of ADRs Logo -->

</template>

<script>
    export default {
        name: "AALogo"
    }
</script>