import { createApp } from 'vue';
import App from './App.vue';

// System settings to use on whole system
import settings from './services/settings';
import support from './services/support';

// System http global communicator
import http from './services/http.js';

// Moment is the datetime javascript lib
import moment from 'moment/moment';
import locale from 'moment/locale/pt-br';

moment.updateLocale(settings.locale, locale);

// Create Vue 3 app
const app = createApp(App);

// Set services on global scope
app.config.globalProperties.$settings = settings;
app.config.globalProperties.$support = support;
app.config.globalProperties.$http = http;
app.config.globalProperties.$moment = moment;

// Setup bootstrap vue 3 framework
import BootstrapVue3 from 'bootstrap-vue-3';

app.use(BootstrapVue3, { locale: settings.locale });

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

// Vue3 toast
import { toast } from 'vue3-toastify'

app.config.globalProperties.$toast = toast;

import 'vue3-toastify/dist/index.css';

// Configure font-awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faCirclePlus,
    faEye,
    faFloppyDisk,
    faIdCard,
    faKey,
    faPenToSquare,
    faRightFromBracket,
    faRotateLeft,
    faTrash,
    faUser
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faCirclePlus,
    faEye,
    faFloppyDisk,
    faIdCard,
    faKey,
    faPenToSquare,
    faRightFromBracket,
    faRotateLeft,
    faTrash,
    faUser,
);

app.component("font-awesome-icon", FontAwesomeIcon);

// Router
import router from './router'

app.use(router);

// Mount Vue 3
app.mount('#app');